<template>
  <v-container fluid>
    <div class="text-center">
      <v-toolbar dense floating>
        <v-text-field
          hide-details
          prepend-icon="fas fa-search"
          single-line
          label="Buscar..."
          @input="indexData()"
          v-model="query"
        ></v-text-field>
        <v-chip class="ma-2" color="primary" text-color="white">
          {{ dataTable.length }} demanda(s)
        </v-chip>
        <v-chip
          v-show="me.role.type == 'responsible'"
          class="ma-2"
          color="success"
          text-color="white"
          :to="{ path: 'demandas/formulario' }"
        >
          Novo
        </v-chip>
        <v-chip
          v-show="me.role.type == 'requester'"
          class="ma-2"
          color="success"
          text-color="white"
          :to="{ path: 'demandas/solicitacao' }"
        >
          Solicitar demanda
        </v-chip>
      </v-toolbar>
    </div>
    <div class="text-center ml-8 mt-4">
      <v-chip-group
        v-model="selection"
        active-class="deep-purple accent-4 white--text"
        column
      >
        <v-chip
          v-for="item in status"
          :key="item.name"
          @click="searchByStatus(item.name)"
        >
          {{ countDemand(item) }} {{ item.name }}
        </v-chip>
      </v-chip-group>
    </div>

    <div class="text-center">
      <v-progress-circular
        :size="100"
        color="primary"
        indeterminate
        v-show="dataLoading"
      ></v-progress-circular>
    </div>

    <Item v-for="item in dataTable" :item="item" :key="item.id" :list="list">
      <template v-slot:item>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Codigo </b> <span> {{ item.code }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Classificação </b> <span> {{ item.rank.name }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0 pr-4">
          <v-card-text class="ml-4 mr-4">
            <b> Descrição </b> <span> {{ item.description }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Prazo </b> <span> {{ item.deadline | formatDate }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Orgão Externo </b> <span> {{ item.agency.name }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Prioridade </b> <span> {{ item.priority.name }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Status </b> <span> {{ item.status.name }} </span>
          </v-card-text>
          <v-divider class="mx-4 mt-0 mb-0"></v-divider>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Participantes </b>
            <v-chip-group column>
              <v-chip
                v-for="participante in item.users"
                :key="'participante' + participante.id"
              >
                {{ participante.name }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-card-text class="ml-4">
            <b> Solicitantes </b>
            <v-chip-group column>
              <v-chip
                v-for="cliente in item.customers"
                :key="'cliente' + cliente.id"
              >
                {{ cliente.name }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-col>
      </template>
      <template v-slot:acoes>
        <v-btn icon color="green" :to="{ path: 'demandas/' + item.id }">
          <v-icon>far fa-eye</v-icon>
        </v-btn>
      </template>
    </Item>
  </v-container>
</template>

<script>
import Item from "@/components/CardListItem/Item";
import ToastService from "@/services/ToastService";
import DemandaService from "@/services/Demanda/DemandaService";
import { mapGetters } from "vuex";
import instanceAxios from "../../plugins/axios";
import moment from "moment";
export default {
  created: async function() {
    await this.indexData();
    this.getStatus();
  },
  components: {
    Item,
  },
  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },
  filters: {
    formatDate: function(el) {
      return moment(el).format("DD/MM/YYYY HH:mm");
    },
  },
  data: () => ({
    DemandaService: new DemandaService(""),
    list: [],
    selection: 6,
    query: "",
    dataTable: [],
    status: [],
    dataLoading: false,
  }),

  methods: {
    countDemand(status) {
      return (
        this.dataTable.filter((el) => el.status.name == status.name).length || 0
      );
    },
    async getStatus() {
      const { data } = await instanceAxios.get("demands/status");
      this.status = data;
    },

    async indexData() {
      try {
        this.dataLoading = true;

        const res = await this.DemandaService.index(
          "demands?search=" + this.query
        );

        console.log(res);
        this.dataTable = res;
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.dataLoading = false;
      }
    },
    searchByStatus(name) {
      if (name == "Todos") {
        this.query = "";
      } else {
        this.query = name;
      }

      this.indexData();
    },
  },
};
</script>

<style></style>
